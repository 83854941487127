import React from 'react';
import Image from 'gatsby-image';

export const getImagesFluid = (queryResult) => {
  return queryResult.fluid.nodes.map(node => {
    return (node.childImageSharp && node.childImageSharp.fluid) || null;
  });
};

const componentUtilsAltDebugOn = process.env.NODE_ENV === `development` && false;

const componentUtilsAltDebugStyle = {
    position: 'absolute',
    top: 0,
    lef: 0,
    background: 'pink',
    padding: '5px 5px',
    opacity: 0.85,
    border: 'solid red 3px'
};

export const renderImageByFileName = (imagesFluid, fileName, alt = '') => {
  const fluid = imagesFluid.find(fluid => {
    return (fluid && fluid.originalName && fluid.originalName === fileName) || null;
  });
  if (!fluid) return null;
  if (componentUtilsAltDebugOn) {
      return (
          <div style={{ position: 'relative' }}>
            <Image fluid={fluid} alt={alt} />
            <div style={componentUtilsAltDebugStyle}>{alt || '??????'}</div>
          </div>
      );
  }
  return (<Image fluid={fluid} alt={alt} />);
};

export const renderFixedHeightImageByFileName = (imagesFluid, fileName, alt = '', height) => {
  const fluid = imagesFluid.find(fluid => {
    return (fluid && fluid.originalName && fluid.originalName === fileName) || null;
  });
  if (!fluid) return null;
  if (!height) return null;
  if (componentUtilsAltDebugOn) {
      return (
          <div style={{ position: 'relative', height: height, width: height * fluid.aspectRatio }}>
            <Image fluid={fluid} alt={alt} height={height} />
            <div style={componentUtilsAltDebugStyle}>{alt || '??????'}</div>
          </div>
      );
  }
  return (<div style={{ height: height, width: height * fluid.aspectRatio }}><Image fluid={fluid} alt={alt} height={height} /></div>);
};

// Have to be the same as breakpoints in _breakpoints.scss
export const breakpoints = {
  xs: 320,
  sm: 600,
  md: 960,
  lg: 1110,
  xl: 1280
};

export const viewports = {
  desktopViewport: 'desktopViewport',
  tabletViewport: 'tabletViewport',
  phoneViewport: 'phoneViewport'
};

// Have to be the same as in _breakpoints.scss
export const containerMaxWidth = 1080;

export const timberIdEudrComplianceSystemPdfUrl = '/files/Deeplai_TimberID_EUDR_compliance-system.pdf'
